import type {
    CreateEventKindPayload,
    DeleteEventKindPayload,
    EventKindInput,
    EventKindPartialInput, EventKindType,
    UpdateEventKindPayload,
} from '~/generated/types';
import { operationFragment } from '~/graphql/fragments/operation';
import { eventKindFragment } from '~/graphql/fragments/eventKinds';

export type CreateEventKindVariables = {
    data: EventKindInput;
}

export type CreateEventKindData = {
    createEventKind: CreateEventKindPayload
}

export const CREATE_EVENT_KIND_MUTATION = gql`
    mutation CreateEventKind($data: EventKindInput!) {
        createEventKind(data: $data) {
            ...eventKindFragment
            ...operationFragment
        }
    }

    ${eventKindFragment}
    ${operationFragment}
`;

export type UpdateEventKindVariables = {
    data: EventKindPartialInput;
}

export type UpdateEventKindData = {
    updateEventKind: UpdateEventKindPayload
}

export const UPDATE_EVENT_KIND_MUTATION = gql`
    mutation UpdateEventKind($data: EventKindPartialInput!) {
        updateEventKind(data: $data) {
            ...eventKindFragment
            ...operationFragment
        }
    }

    ${eventKindFragment}
    ${operationFragment}
`;

export type DeleteEventKindVariables = {
    id: string;
}

export type DeleteEventKindData = {
    deleteEventKind: DeleteEventKindPayload;
}

export const DELETE_EVENT_KIND_MUTATION = gql`
    mutation DeleteEventKind($id: GlobalID!) {
        deleteEventKind(data: {id: $id}) {
            ...operationFragment
        }
    }

    ${operationFragment}
`;

export type MergeEventKindsVariables = {
    eventKindIds: string[];
    name: string;
}

export type MergeEventKindsData = {
    mergeEventKinds: EventKindType;
}

export const MERGE_EVENT_KINDS_MUTATION = gql`
    mutation MergeEventKinds($eventKindIds: [GlobalID!]!, $name: String!) {
        mergeEventKinds(eventKindIds: $eventKindIds, name: $name) {
            id
        }
    }
`;
